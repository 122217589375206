import React from "react";
import {
  SEO,
  MarkdownContent,
  Image,
  BasicCarousel,
  componentIterator,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class WoodFireCateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "58615d09-4bfc-4083-9e90-4d25273853a8",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "6f97c273-ac0a-42b3-8073-2d43065a830b",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "5f8a3ae9-cd5e-4993-8080-54b6a8df4e78",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={7}
              textAlign={"right"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "7b973df6-03ae-4b75-8c25-8ca90acc6bb6",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={""} style={{ padding: 16 }} width={7}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "150d2a4a-321c-4697-9f52-309ab0b546ce",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"lsykeoaqspbxpnxl"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"ldyscianwnkjtpfc"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "0077d35e-e683-4a56-a68f-d7cdfe0a7eaa",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"zkpyyioalltmzkzb"}
            style={{ padding: 16 }}
            textAlign={"left"}
            verticalAlign={"top"}
            columns={1}
          >
            <Grid.Column
              className={"zhijhzoncfbaxtda"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ce2f9554-1cf0-4fd1-81c8-481a3c6362ff",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"eznmmzyvcyzajazz"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"rhgflefwgfdjuvrg"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "7c22bff2-f04d-49af-b3ba-187488a49d9b",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"jpihtsjjvkvikitb"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"brhhymbrcilqfuhq"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "8209d71d-9278-4da0-82c4-ada852601928",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      background: "rgba(0,0,0,0.62)",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "bf9fdcdd-9247-46c5-9ab1-78668a1254c7",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"vuqkwxulmyviubgk"}
            style={{ padding: "16px" }}
            textAlign={"left"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"kcqppykijovdiztl"}
              style={{ padding: 16 }}
              width={4}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "81779ffe-6d8e-4656-926a-5210dc3fb8dc",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"srpnyiggcckgtuog"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "aad61eb9-a2df-475a-b584-e36457d68bda",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "1e20ae6b-e6f7-476b-a295-8e0aa3c0b7e2",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "fe60c92c-ef20-4668-98df-cc50410990be",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "660dbeb9-8419-4533-b1e2-dc65e57b1c0c",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "0325051b-7f0e-41c2-b9af-34cc65a85c8c",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "6b98dfa8-7ec0-4044-b3a2-e4c29f9160ea",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "421031c4-a57c-4b28-a246-db02c7a56515",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "107d68e4-e216-47d1-a845-b451e4cc220e",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "094bee11-c271-4237-962b-170fa32dbde6",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"zlndguvnmcyofjhj"}
              style={{ padding: 16 }}
              width={2}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "4bc2ae6c-ef17-40d6-ae03-0d069a359c0d",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "665bed53-436a-4b31-90cf-cfd3ae937ab8",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c98b2ee7-ec4b-4409-80fb-0204818d6904",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "3df7f4ff-4bce-4020-ad36-ada92d150128",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "663a978c-bb92-4e31-915a-f35c91f81af1",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "7ad4bc11-dfa2-42f3-ba72-c4b2c96bdf62",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "014759bf-070a-4992-9bd4-f74b441f2491",
                    defaultValue: "",
                  })}
                />
              </Segment>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "4464e9b0-d2cb-49a3-b6fa-e9697cfee16a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"baprkqczbryocvxo"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"bwmldmonuxtpkqbb"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "51e587f7-80ed-410a-97ec-3e66609de0fe",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"xrfpwyvfqzrpuxba"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"kuoqnphuxxoszrtt"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "4bd94bb8-e532-4dcc-9b3f-de6b9a446a17",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={"ingqmarqiaorezbq"} style={{ padding: 16 }}>
            <Grid.Column
              className={"zzwwfgiqibhduajz"}
              style={{ padding: 16 }}
              width={16}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <BasicCarousel
                  className={"image-block"}
                  style={{}}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "BasicCarousel",
                      componentIdentifier:
                        "2ec57d8e-2862-4fa8-a606-387e981299c4",
                    }),
                    component: <Image className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={0} />
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22144 }) {
      title
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
